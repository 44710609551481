import { MessageFormatterService } from './../../services/messageFormatter.service';
import { HeaderService } from './../../services/header.service';
import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import{PianoIntegrazioneService} from "../integrazione/piano-integrazione.service";
import {Subscription} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {ConfirmDialogService} from "../../components/confirm-dialog/confirm-dialog.service";

@Component({
  selector: 'app-banner-integrazione',
  templateUrl: './banner-integrazione.component.html',
  styleUrls: ['./banner-integrazione.component.css']
})
export class BannerIntegrazioneComponent implements OnInit {

  form = new FormGroup({
    banner_img: new FormControl("", [Validators.required]),
  });
  bannerData: any;
  subscriptions: Subscription[] = [];

	//saveButtonClicked := true when saved button is clicked
	saveButtonClicked: boolean = false;
  
  constructor(
      private pianoIntegrazioneService: PianoIntegrazioneService,
      private toastr: ToastrService,
      private messageFormatter: MessageFormatterService,
      private confirmDialogService: ConfirmDialogService,
      private headerService: HeaderService
  ) { }

  ngOnInit(): void {
    this.loadData();
		this.headerService.setHeaderTitle('Banner integrazione');
  }

  loadData() {
    const sub = this.pianoIntegrazioneService.getBannerSupplementPlan().subscribe({
      next: (res: any) => {
        if (res.success) {
          this.bannerData = res.data;
          this.form = new FormGroup({
            banner_img: new FormControl(this.bannerData.file_name),
          })
        }
      },
      error: err => {
      }
    });
    this.subscriptions.push(sub);
  }

  fileChanged($event, field) {
    this.form.get(field).setValue($event);
  }

  isValidFile(field: any) {
    if (field == null || field.hasOwnProperty('name')) return false
    return true
  }

  buildRequest(image): FormData
  {
    let formData = new FormData();
    formData.append("image", image);
    return formData;
  }

  updateBanner() {
    const banner_img = this.form.get("banner_img").value;
    console.log(this.form.get("banner_img"));
    
    let request = this.buildRequest(banner_img);
    const sub = this.pianoIntegrazioneService.postBannerSupplementPlan(request).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.toastr.success(res.message, this.messageFormatter.getSuccessTitle(), {
            positionClass: 'toast-bottom-left',
            tapToDismiss: true,
          });
					this.saveButtonClicked = !this.saveButtonClicked;
          this.loadData();
        } else {
          this.confirmDialogService.confirm(this.messageFormatter.getErrorTitle(), res.message);
        }
      }
    });
    this.subscriptions.push(sub);
  }

}
