import { CommonModule } from "@angular/common";
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DxPieChartModule, DxVectorMapModule } from 'devextreme-angular';
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";

import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { Ng2SmartTableModule } from "ng2-smart-table";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ActionTableComponent } from './action-table/action-table.component';
import { AddMealComponent } from './add-meal/add-meal.component';
import { AssociateExerciseComponent } from './associate-exercise/associate-exercise.component';
import { AssociateSuperserieComponent } from './associate-superserie/associate-superserie.component';
import { AssociatesFoodComponent, NgbdModal1Content, NgbdModal2Content, NgbdModal3Content } from './associates-food/associates-food.component';
import { CheckboxNg2SmartTableComponent } from './checkbox-ng2-smart-table/checkbox-ng2-smart-table.component';
import { ChooseGenderComponent } from './choose-gender/choose-gender.component';
import { ClonePlanFoodComponent } from './clone-plan-food/clone-plan-food.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DropdownActionBtnComponent } from './dropdown-action-btn/dropdown-action-btn.component';
import { EditPlanModalComponent } from "./edit-plan-modal/edit-plan-modal.component";
import { FileInputComponent } from "./file-input/file-input.component";
import { FixedPluginComponent } from './fixed-plugin/fixed-plugin.component';
import { FooterComponent } from "./footer/footer.component";
import { GenericModalComponent } from "./generic-modal/generic-modal.component";
import { ImageUploadComponent } from "./image-upload/image-upload.component";
import { ModalFoodPlanComponent } from './modal-food-plan/modal-food-plan.component';
import { ModalPlanWorkoutForUserComponent } from './modal-plan-workout-for-user/modal-plan-workout-for-user.component';
import { ModalSupplementPlanForUserComponent } from './modal-supplement-plan-for-user/modal-supplement-plan-for-user.component';
import { NgbdModalBasic } from "./modal/modal.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { NotificationsSidebarComponent } from './notifications-sidebar/notifications-sidebar.component';
import { PictureUploadComponent } from './picture-upload/picture-upload.component';
import { FilePipePipe } from "./pipe/file-pipe.pipe";
import { FilterPipe } from "./pipe/filter.pipe";
import { JwtFilePipe } from "./pipe/jwtFile.pipe";
import { PdfFilePipe } from "./pipe/pdfFile.pipe";
import { SortByPipe } from "./pipe/sortBy.pipe";
import { RangeDataComponent } from './range-data/range-data.component';
import { SidebarComponent } from "./sidebar/sidebar.component";
import { StoreExerciseComponent } from './store-exercise/store-exercise.component';
import { StorePianoAlimentareComponent } from './store-piano-alimentare/store-piano-alimentare.component';
import { StorePianoAllenamentoComponent } from './store-piano-allenamento/store-piano-allenamento.component';
import { StorePianoIntegrazioneComponent } from './store-piano-integrazione/store-piano-integrazione.component';
import { StoreSessionComponent } from './store-session/store-session.component';
import { VectorMapComponent1 } from "./vector-map/vector-map.component";
import { WidgetInfoTrainersComponent } from './widget-info-trainers/widget-info-trainers.component';
import { WidgetInfoComponent } from './widget-info/widget-info.component';
import { ActionToggleComponent } from './action-toggle/action-toggle.component';

@NgModule({
    imports: [CommonModule, HttpClientModule, RouterModule, FormsModule, JwBootstrapSwitchNg2Module, NgbModule, DxVectorMapModule,
        DxPieChartModule, ReactiveFormsModule, AngularMultiSelectModule, Ng2SmartTableModule, InfiniteScrollModule],
  declarations: [
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    VectorMapComponent1,
    NgbdModalBasic,
    ImageUploadComponent,
    FileInputComponent,
    PictureUploadComponent,
    FixedPluginComponent,
    ChooseGenderComponent,
    WidgetInfoComponent,
    WidgetInfoTrainersComponent,
    ActionTableComponent,
    ModalFoodPlanComponent,
    AddMealComponent,
    ActionTableComponent,
    DropdownActionBtnComponent,
    StorePianoAlimentareComponent,
    AssociatesFoodComponent,
    NgbdModal1Content,
    NgbdModal2Content,
    NgbdModal3Content,
    ConfirmDialogComponent,
    RangeDataComponent,
    ClonePlanFoodComponent,
    StorePianoAllenamentoComponent,
    ModalPlanWorkoutForUserComponent,
    AssociateExerciseComponent,
    StoreSessionComponent,
    AssociateSuperserieComponent,
    StorePianoIntegrazioneComponent,
    JwtFilePipe,
    SortByPipe,
    FilePipePipe,
    PdfFilePipe,
    FilterPipe,
    ModalSupplementPlanForUserComponent,
    StoreExerciseComponent,
    EditPlanModalComponent,
    GenericModalComponent,
    NotificationsSidebarComponent,
    CheckboxNg2SmartTableComponent,
    ActionToggleComponent
  ],
    exports: [
        NavbarComponent,
        FooterComponent,
        SidebarComponent,
        VectorMapComponent1,
        NgbdModalBasic,
        ImageUploadComponent,
        PictureUploadComponent,
        FileInputComponent,
        FixedPluginComponent,
        ChooseGenderComponent,
      WidgetInfoComponent,
        WidgetInfoTrainersComponent,
        ActionTableComponent,
        ModalFoodPlanComponent,
        AddMealComponent,
        StorePianoAlimentareComponent,
        AssociatesFoodComponent,
        NgbdModal1Content,
        NgbdModal2Content,
        NgbdModal3Content,
        ClonePlanFoodComponent,
        StorePianoAllenamentoComponent,
        ModalPlanWorkoutForUserComponent,
        AssociateExerciseComponent,
        RangeDataComponent,
        StoreSessionComponent,
        AssociateSuperserieComponent,
        StorePianoIntegrazioneComponent,
        JwtFilePipe,
        SortByPipe,
        FilePipePipe,
        PdfFilePipe,
        FilterPipe,
        ModalSupplementPlanForUserComponent,
        StoreExerciseComponent,
        EditPlanModalComponent,
        GenericModalComponent,
        NotificationsSidebarComponent
    ]
})
export class ComponentsModule {}
