<div class="main-section banner-integrazione">
    <p>Inserisci l'immagine che sarà visualizzata come banner nell'app mobile, <br> ricorda che le dimensioni ideali raccomandate sono 1024 × 236 pixel.</p>
    <div class="mt-4 p-4">
        <form [formGroup]="form" class="w-100">
            <app-image-upload
                    label="Aggiungi foto"
                    formCtrlName="banner_img"
                    [image]="bannerData.file_name"
                    (onFileChanged)="fileChanged($event, 'banner_img')"
                    *ngIf="bannerData"
                    [buttonSaveClicked]="saveButtonClicked"
            >
            </app-image-upload>
            <button 
                class="btn btn-primary border-radius-small w-100 d-flex align-items-center col-md-4"
                (click)="updateBanner()"
                [disabled]="form.get('banner_img').value == null || form.get('banner_img').value?.name == null"
            >
                <span class="w-75">SALVA</span>
                <i class="fas fa-arrow-right"></i>
            </button>
        </form>
    </div>
</div>
