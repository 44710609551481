import { MessageFormatterService } from "./../../services/messageFormatter.service";
import { ConfirmDialogService } from "./../confirm-dialog/confirm-dialog.service";
import { PianoAllenamentoService } from "./../../pages/piano-allenamento/piano-allenamento.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActionTableComponent } from "../action-table/action-table.component";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";

declare global {
  interface Window {
    test: any;
    data: any;
  }
}

@Component({
  selector: "app-associate-superserie",
  templateUrl: "./associate-superserie.component.html",
  styleUrls: ["./associate-superserie.component.css"],
})
export class AssociateSuperserieComponent implements OnInit {
  //current user actually logged
  currentUser: any;

  subscriptions: Subscription[] = [];
  @ViewChild("infoExercise") infoExercise: ElementRef;
  @ViewChild("completeSuperserie") completeSuperserie: ElementRef;
  @ViewChild("addSuperserieModal") addSuperserieModal: ElementRef;
  addSuperserie_Settings: any;
  addSuperserie_Source: any;

  gruppiMuscolari = [];

  exerciseName: string;
  exerciseNote: string;
  exerciseId: number;
  classListBtn: string;
  added: boolean = false;

  arraySelectedSeries: any = [];
  supersetForm: FormGroup;
  exerciseArrayRequest = [];
  cooldownSeries = [];
  planWorkoutId: number;
  sessionActivated: number;

  // select Video HowTo
  dropdownListHowTo = [];
  selectedItemsHowTo = [];
  dropdownSettingsHowTo = {};

  selectedSeries: any;
  exercisesData = {};
  edit = false;
  selectedGroup = 0;

  constructor(
    private pianoAllenamentoService: PianoAllenamentoService,
    private modalService: NgbModal,
    private messageFormatter: MessageFormatterService,
    private toastr: ToastrService,
    private confirmDialogService: ConfirmDialogService // private fb: FormBuilder,
  ) {
    pianoAllenamentoService.planWorkoutId$.subscribe((val) => (this.planWorkoutId = val));
    pianoAllenamentoService.sessionActivated$.subscribe((val) => (this.sessionActivated = val));
  }

  ngOnInit(): void {
    this.currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : "";
    window.test = this;
  }

  loadIndexExercise(idType: number = null) {
    this.selectedGroup = idType;
  }

  loadExerciseList(): void {
    this.addSuperserie_Source = this.pianoAllenamentoService.getExercise();
  }

  loadExerciseSettings() {
    this.addSuperserie_Settings = {
      noDataMessage: "Nessun dato da visualizzare",
      mode: "external",
      hideSubHeader: false,
      actions: false,
      sort: false,
      columns: {
        name: {
          title: "Nome",
          filter: true,
        },
        dettaglio: {
          type: "custom",
          filter: false,
          sort: false,
          renderComponent: ActionTableComponent,
          onComponentInitFunction: (instance) => {
            const ref = this;
            instance.actionEmit.subscribe((value) => {
              if (value === "aggiungi") {
                if (this.isSelected(instance.rowData.id)) {
                  this.confirmDialogService
                    .confirm("Stai per rimuovere l'esercizio " + instance.rowData.name, "Sei sicuro di voler continuare?")
                    .then((res) => {
                      if (res) {
                        const indexOfObject = this.exerciseArrayRequest.findIndex((object) => {
                          return object.exercise_id == instance.rowData.id;
                        });
                        this.exerciseArrayRequest.splice(indexOfObject, 1);

                        this.arraySelectedSeries = [];
                        this.loadExerciseSettings();
                      }
                    })
                    .catch(() => console.log("Error"));
                } else {
                  this.exerciseName = instance.rowData.name;
                  this.exerciseId = instance.rowData.id;
                  this.exerciseNote = instance.rowData.note;
                  this.classListBtn = instance.value[0].classList;

                  if (this.exerciseArrayRequest.length > 0) {
                    // number select already set
                    this.selectSeries();
                  }

                  this.openModal("infoExercise");
                }
              }
            });
          },
          valuePrepareFunction: (cell, exercise) => {
            const ritorno = [
              {
                text: this.isSelected(exercise.id) ? "rimuovi" : "aggiungi",
                link: "",
                emit: "aggiungi",
                title: "aggiungi esercizio",
                btnId: "aggiungi_" + exercise.id,
                stackIcon: false,
                stackClass: null,
                iconClassList: [],
                iconClass: this.isSelected(exercise.id) ? "fas fa-minus" : "fas fa-plus",
                classList: this.isSelected(exercise.id) ? "action-btn btn added" : "action-btn btn",
              },
            ];
            return ritorno;
          },
        },
      },
    };
  }

  getRequest() {
    const series = Object.values(this.exercisesData).map((v: any) => {
      if (v.technique != null && v.technique.length > 0) {
        v["howtovideo_id"] = v.technique[0].id;
      }
      delete v["technique"];

      return v;
    });

    const note = document.getElementById("note") as HTMLInputElement | null;
    const valueNote = note?.value;

    let request = {
      exercise_id: this.exerciseId,
      name: this.exerciseName,
      series: series,
      note: valueNote,
    };
    return request;
  }

  assign() {
    let request = this.getRequest();

    if (request.series.filter((s) => s.repetitions == null).length > 0) {
      this.confirmDialogService.confirm("ATTENZIONE", "Tutti i campi sono obbligatori.");
      return;
    }

    this.exerciseArrayRequest.push(request);

    this.modalService.dismissAll();

    this.openModal("addSuperserieModal");
  }

  confirmSuperserie() {
    /* cooldown series entered first time only*/
    if (this.cooldownSeries[0]?.cooldown == null) {
      this.confirmDialogService.confirm("ATTENZIONE", "Inserire i tempi di recupero");
      return;
    }
    /* fill other cooldown values */
    let timeBreak = this.cooldownSeries[0].cooldown;
    this.cooldownSeries.forEach((series) => {
      series.cooldown = timeBreak;
    });

    let request = {
      exercises: this.exerciseArrayRequest,
      series: this.cooldownSeries,
    };

    const sub = this.pianoAllenamentoService.newSuperserie(this.planWorkoutId, this.sessionActivated, request).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.toastr.success(res.message, "Operazione eseguita con successo!", {
            positionClass: "toast-bottom-left",
            tapToDismiss: true,
          });
          this.exerciseArrayRequest = [];
          this.cooldownSeries = [];
          this.arraySelectedSeries = [];
          this.pianoAllenamentoService.notifyAboutChange();
          this.modalService.dismissAll();
        }
      },
      error: (err) => {
        this.toastr.error(err.error.message, this.messageFormatter.getErrorTitle(), {
          positionClass: "toast-bottom-left",
          tapToDismiss: true,
        });
      },
    });
    this.subscriptions.push(sub);
  }

  // checkSeries(val1, val2, val3, arr){
  // 	if(val2 != null){
  // 		arr.push({is_time: val1, repetitions: val2, howtovideo_id: val3})
  // 	}
  // }

  isSelected(id: number): boolean {
    return this.exerciseArrayRequest.filter((e) => e.exercise_id == id).length > 0;
    // if (this.superserieList.some((item) => item.id == id)) {
    // 	return true;
    // } else {
    // 	return false;
    // }
  }

  openModal(whichModal: string) {
    if (whichModal == "addSuperserieModal") {
      this.modalService.open(this.addSuperserieModal, { size: "lg", backdrop: "static", windowClass: "largeModal", centered: true });
      this.loadExerciseSettings();
      this.loadExerciseList();
      this.loadTypesExercise();
    } else if (whichModal == "infoExercise") {
      this.edit = false;
      this.exercisesData = {};

      this.modalService.open(this.infoExercise, { size: "lg", backdrop: "static", windowClass: "largeModal", centered: true });
      this.selectedItemsHowTo = [];
      this.dropdownSettingsHowTo = {
        singleSelection: true,
        text: "Tecnica",
        selectAllText: "Select All",
        unSelectAllText: "UnSelect All",
        enableSearchFilter: false,
        classes: "custom-input-fill",
        lazyLoading: true,
        position: "bottom",
        tagToBody: false,
        autoPosition: false,
        maxHeight: "300",
        primaryKey: "id",
        labelKey: "name",
      };
      this.getDropdownData();
      if (this.exerciseArrayRequest.length == 0) {
        this.selectedSeries = 0;
      }
      this.selectSeries();
    } else if (whichModal == "completeSuperserie") {
      if (this.exerciseArrayRequest.length < 2) {
        this.confirmDialogService.confirm("ATTENZIONE", "Selezionare almeno 2 esercizi per creare una superserie.");
        return;
      } else {
        this.cooldownSeries = [];
        for (let e of this.exerciseArrayRequest[0].series) {
          this.cooldownSeries.push({ cooldown: null });
        }

        this.modalService.open(this.completeSuperserie, { size: "lg", backdrop: "static", windowClass: "largeModal", centered: true });
      }
    }
  }

  getDropdownData() {
    if (this.dropdownListHowTo.length == 0) {
      const sub = this.pianoAllenamentoService.listVideoHowTo().subscribe({
        next: (res: any) => {
          if (res.success) {
            this.dropdownListHowTo = res.data;
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
      this.subscriptions.push(sub);
    }
  }

  loadTypesExercise() {
    this.pianoAllenamentoService.getTypesExercise().subscribe({
      next: (res: any) => {
        if (res.success) {
          this.gruppiMuscolari = res.data;
          this.gruppiMuscolari.unshift({ id: 0, name: "Tutti" });
        }
      },
      error: (err) => {
        this.toastr.error(err.error.message, this.messageFormatter.getErrorTitle(), {
          positionClass: "toast-bottom-left",
          tapToDismiss: true,
        });
      },
    });
  }

  initExercise() {
    return {
      is_time: false,
      repetitions: null,
      technique: null,
    };
  }

  selectSeries() {
    this.arraySelectedSeries = [...Array(parseInt(this.selectedSeries)).keys()];
    this.arraySelectedSeries.forEach((i) => {
      if (!this.exercisesData.hasOwnProperty(i)) {
        this.exercisesData[i] = this.initExercise();
      }
    });
    const exceeded = Object.keys(this.exercisesData).filter((k) => !this.arraySelectedSeries.includes(parseInt(k)));
    exceeded.forEach((k) => delete this.exercisesData[k]);
  }

  deselectFirstRow() {
    setTimeout(function () {
      const tabelle = Array.from(document.getElementsByTagName("table"));
      tabelle.forEach((t) => {
        for (let i = 0, row; (row = t.rows[i]); i++) {
          if (row.classList.contains("selected")) {
            row.classList.remove("selected");
          }
        }
      });
    }, 200);
  }
}
