<div id="notificationsSidepanel" class="sidepanel" infinite-scroll [scrollWindow]="false"
    [infiniteScrollDistance]="scrollDistance" (scrolled)="onScrollDown()" (scroll)="scrollTrigger($event)">
    <div class="notificationsSidebar_header">
        <div class="notificationsSidebar_header-title d-flex align-items-center justify-content-between">
            <span>Notifiche</span>
            <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">
                <i class="now-ui-icons ui-1_simple-remove"></i>
            </a>
        </div>
        <div class="notificationsSidebar_header-buttons d-flex">
            <button class="btn add-button btn-new active" [ngClass]="{'active': currentType === 'New'}"
                (click)="activeList('New')">
                <span class="m-0">Nuove</span>
                <span *ngIf="totalNotifications !== 0" class="badge">{{totalNotifications}}</span>
            </button>
            <button class="btn add-button btn-chronology" [ngClass]="{'active': currentType === 'Readed'}"
                (click)="activeList('Readed')">
                <span class="m-0">Già lette</span>
            </button>
            <button *ngIf="currentUser.is_admin" class="btn add-button btn-chronology" [ngClass]="{'active': currentType === 'Trainer'}"
                (click)="activeList('Trainer')">
                <span class="m-0">Trainer</span>
            </button>
        </div>
    </div>
    <div class="container-notifications" [ngClass]="{'bounce': bounce, 'bounce_up': bounce_up}">
        <div *ngFor="let notification of notifications" class="notification"
            (click)="openNotification(currentType, notification)">
            <div class="notification-name" [ngClass]="{'d-none': notLoader && idNotLoader == notification.id}">
                {{notification.title}}</div>
            <div class="notification-message" [ngClass]="{'d-none': notLoader && idNotLoader == notification.id}">
                {{notification.message}}</div>
            <div class="notification-time" [ngClass]="{'d-none': notLoader && idNotLoader == notification.id}">
                {{notification.created_at}}</div>

            <div class="d-flex w-100 align-items-center justify-content-center"
                *ngIf="notLoader && idNotLoader == notification.id">
                <div class="spinner-grow" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div class="d-flex w-100 align-items-center justify-content-center" *ngIf="loader">
            <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>