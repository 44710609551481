import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { BannerIntegrazioneComponent } from "./pages/banner-integrazione/banner-integrazione.component";
import { ListaIntegratoriComponent } from "./pages/integrazione/lista-integratori/lista-integratori.component";
import { InvioNotificheMassiveComponent } from "./pages/invio-notifiche-massive/invio-notifiche-massive.component";
import { AlimentiComponent } from "./pages/piano-alimentare/alimenti/alimenti.component";
import { ListaEserciziComponent } from "./pages/piano-allenamento/lista-esercizi/lista-esercizi.component";
import { NewsletterComponent } from "./pages/newsletter/newsletter.component";

const currentUser = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser"))
  : "";
const isAdmin = currentUser ? currentUser.is_admin : false;

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "/pages/login",
    pathMatch: "full",
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "pages",
        loadChildren: () =>
          import("./pages/pages/pages.module").then((x) => x.PagesModule),
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/utenti/utenti.module").then((x) => x.UtentiModule),
      },
      {
        path: "utenti",
        loadChildren: () =>
          import("./pages/utenti/utenti.module").then((x) => x.UtentiModule),
      },
      ...(isAdmin
        ? [
            {
              path: "trainers",
              loadChildren: () =>
                import("./pages/trainers/trainers.module").then(
                  (x) => x.TrainersModule
                ),
            },
          ]
        : []),
      {
        path: "piano-allenamento",
        loadChildren: () =>
          import("./pages/piano-allenamento/piano-allenamento.module").then(
            (x) => x.PianoAllenamentoModule
          ),
      },
      {
        path: "piano-alimentare",
        loadChildren: () =>
          import("./pages/piano-alimentare/piano-alimentare.module").then(
            (x) => x.PianoAlimentareModule
          ),
      },
      {
        path: "integrazione",
        loadChildren: () =>
          import("./pages/integrazione/integrazione.module").then(
            (x) => x.IntegrazioneModule
          ),
      },
      {
        path: "video-how-to",
        loadChildren: () =>
          import("./pages/video-how-to/video-how-to.module").then(
            (x) => x.VideoHowToModule
          ),
      },
      {
        path: "chat",
        loadChildren: () =>
          import("./pages/chat/chat.module").then((x) => x.ChatModule),
      },
      {
        path: "generic-coupon",
        loadChildren: () =>
          import("./pages/generic-coupon/generic-coupon.module").then(
            (x) => x.GenericCouponModule
          ),
      },
      {
        path: "lista-voucher",
        loadChildren: () =>
          import("./pages/voucher-list/voucher-list.module").then(
            (x) => x.VoucherListModule
          ),
      },
      {
        path: "newsletter",
        loadChildren: () =>
          import("./pages/newsletter/newsletter.module").then(
            (x) => x.NewsletterModule
          ),
      },
      {
        path: "lista-alimenti",
        component: AlimentiComponent,
      },
      {
        path: "lista-integratori",
        component: ListaIntegratoriComponent,
      },
      {
        path: "lista-esercizi",
        component: ListaEserciziComponent,
      },
      {
        path: "banner-integrazione",
        component: BannerIntegrazioneComponent,
      },
      {
        path: "invio-notifiche",
        component: InvioNotificheMassiveComponent,
      },
      {
        path: "custom-components",
        loadChildren: () =>
          import("./pages/custom-components/custom-components.module").then(
            (x) => x.CustomComponentsModule
          ),
      },
      {
        path: "components",
        loadChildren: () =>
          import("./pages/components/components.module").then(
            (x) => x.ComponentsPageModule
          ),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "utenti",
  },
];
