import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";

let currentUser;

var misc: any = {
  sidebar_mini_active: true,
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype?: string;
  iconPath?: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

const adminViewsAccess: RouteInfo[] = [
  {
    path: "/utenti",
    title: "Utenti",
    type: "link",
    icontype: "users_single-02",
  },
  {
    path: "/trainers",
    title: "Trainers",
    type: "link",
    iconPath: "../../../assets/img/trainer-side.svg",
  },
  {
    path: "/contabilita/generale",
    title: "Contabilità",
    type: "link",
    icontype: "business_money-coins",
  },
  {
    path: "/piano-allenamento",
    title: "Piano allenamento",
    type: "link",
    icontype: "sport_user-run",
  },
  {
    path: "/piano-alimentare",
    title: "Piano alimentare",
    type: "link",
    icontype: "shopping_box",
  },
  {
    path: "/integrazione",
    title: "Integrazione",
    type: "link",
    icontype: "health_ambulance",
  },
  {
    path: "/video-how-to",
    title: "Tecniche",
    type: "link",
    icontype: "media-1_button-play",
  },
  {
    path: "/chat",
    title: "Chat",
    type: "link",
    icontype: "ui-1_send",
  },
  {
    path: "/generic-coupon",
    title: "Coupon",
    type: "link",
    icontype: "location_bookmark",
  },
  {
    path: "/lista-voucher",
    title: "Lista voucher",
    type: "link",
    icontype: "ui-1_email-85",
  },
  {
    path: "/lista-alimenti",
    title: "Lista alimenti",
    type: "link",
    icontype: "design_bullet-list-67",
  },
  {
    path: "/lista-integratori",
    title: "Lista integratori",
    type: "link",
    icontype: "design_bullet-list-67",
  },
  {
    path: "/lista-esercizi",
    title: "Lista esercizi",
    type: "link",
    icontype: "design_bullet-list-67",
  },
  {
    path: "/banner-integrazione",
    title: "Banner integrazione",
    type: "link",
    icontype: "design-2_ruler-pencil",
  },
  {
    path: "/invio-notifiche",
    title: "Invio notifiche massive",
    type: "link",
    icontype: "ui-2_chat-round",
  },
  {
    path: "/newsletter",
    title: "Newsletter",
    type: "link",
    iconPath: "../../../assets/img/sidebarNewsletter.svg",
  },
];

const trainerViewsAccess: RouteInfo[] = [
  {
    path: "/utenti",
    title: "Utenti",
    type: "link",
    icontype: "users_single-02",
  },
  {
    path: `/contabilita/generale/dettaglio-trainer`,
    title: "Contabilità",
    type: "link",
    icontype: "business_money-coins",
  },
  {
    path: "/piano-allenamento",
    title: "Piano allenamento",
    type: "link",
    icontype: "sport_user-run",
  },
  {
    path: "/piano-alimentare",
    title: "Piano alimentare",
    type: "link",
    icontype: "shopping_box",
  },
  {
    path: "/integrazione",
    title: "Integrazione",
    type: "link",
    icontype: "health_ambulance",
  },
  {
    path: "/video-how-to",
    title: "Tecniche",
    type: "link",
    icontype: "media-1_button-play",
  },
  {
    path: "/chat",
    title: "Chat",
    type: "link",
    icontype: "ui-1_send",
  },
  {
    path: "/lista-alimenti",
    title: "Lista alimenti",
    type: "link",
    icontype: "design_bullet-list-67",
  },
  {
    path: "/lista-integratori",
    title: "Lista integratori",
    type: "link",
    icontype: "design_bullet-list-67",
  },
  {
    path: "/lista-esercizi",
    title: "Lista esercizi",
    type: "link",
    icontype: "design_bullet-list-67",
  },
  {
    path: "/banner-integrazione",
    title: "Banner integrazione",
    type: "link",
    icontype: "design-2_ruler-pencil",
  },
];

//Menu Items
export const ROUTES: RouteInfo[] = [];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = false;

  constructor(private toastr: ToastrService) {}

  ngOnInit() {
    currentUser = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : "";
    this.initUserAccess(
      currentUser.is_admin ? adminViewsAccess : trainerViewsAccess
    );
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }

  initUserAccess(viewsAccess: RouteInfo[]) {
    ROUTES.splice(0, ROUTES.length);
    viewsAccess.forEach((route) => ROUTES.push(route));
  }

  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }
  myFunc(event, menuitem) {
    event.preventDefault();
    event.stopPropagation();
    this.sleep(10);
    if (menuitem.isCollapsing === undefined) {
      menuitem.isCollapsing = true;

      // menuitem.isCollapsed = !menuitem.isCollapsed;

      var element = event.target;
      while (
        element.getAttribute("data-toggle") != "collapse" &&
        element != document.getElementsByTagName("html")[0]
      ) {
        element = element.parentNode;
      }
      element = element.parentNode.children[1];

      if (
        element.classList.contains("collapse") &&
        !element.classList.contains("show")
      ) {
        element.classList = "before-collapsing";
        var style = element.scrollHeight;

        element.classList = "collapsing";
        setTimeout(function () {
          element.setAttribute("style", "height:" + style + "px");
        }, 1);
        setTimeout(function () {
          element.classList = "collapse show";
          element.removeAttribute("style");
          menuitem.isCollapsing = undefined;
        }, 350);
      } else {
        var style = element.scrollHeight;
        setTimeout(function () {
          element.setAttribute("style", "height:" + (style + 20) + "px");
        }, 3);
        setTimeout(function () {
          element.classList = "collapsing";
        }, 3);
        setTimeout(function () {
          element.removeAttribute("style");
        }, 20);
        setTimeout(function () {
          element.classList = "collapse";
          menuitem.isCollapsing = undefined;
        }, 400);
      }
    }
  }
  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("sidebar-mini")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("sidebar-mini");
      misc.sidebar_mini_active = false;
      this.showSidebarMessage("Sidebar mini deactivated...");
    } else {
      body.classList.add("sidebar-mini");
      this.showSidebarMessage("Sidebar mini activated...");
      misc.sidebar_mini_active = true;
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  showSidebarMessage(message) {
    this.toastr.show(
      '<span class="now-ui-icons ui-1_bell-53"></span>',
      message,
      {
        timeOut: 4000,
        closeButton: true,
        enableHtml: true,
        toastClass: "alert alert-danger alert-with-icon",
        positionClass: "toast-top-right",
      }
    );
  }
}
