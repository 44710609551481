import { ToastrService } from "ngx-toastr";
import { EventEmitter, Injectable } from "@angular/core";
import {
  Firestore,
  collection,
  DocumentData,
  doc,
  setDoc,
  query,
  orderBy,
  limit,
  getDocs,
  startAfter,
  onSnapshot,
  updateDoc,
  where,
  startAt,
  getDoc,
  or,
} from "@angular/fire/firestore";
import * as uuid from "uuid";
import firebase from "firebase/compat";
import Unsubscribe = firebase.Unsubscribe;
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Storage, ref, uploadBytesResumable, getDownloadURL } from "@angular/fire/storage";
import { getMessaging, onMessage } from "firebase/messaging";
import { PushNotification } from "./PushNotification.model";

declare global {
  interface Window {
    srv: any;
    constructor: any;
  }
}

@Injectable({
  providedIn: "root",
})
export class FirebaseService {
  private collectionName = "chats";
  public messagesLimit = 5;

  private message2doc = {};

  private userList: any;
  private chats$ = new EventEmitter<any>();

  private messagesStorage = [];

  private messages = new EventEmitter<any[]>();

  private superAdminUid = null;

  // map uid receiver - seen|unseen as boolean
  private messagesFromReceivers = {};
  private messagesFromReceiversEmitter = new EventEmitter<any>();

  private unsubscribeWaitForMessages: Unsubscribe = null;
  private unsubscribeWaitForOtherMessages: Unsubscribe = null;

  private HTTPheaders;
  private options;

  //push notifications
  private newPushNotification = new EventEmitter<PushNotification>();

  constructor(private afs: Firestore, private http: HttpClient, private storage: Storage, private toastr: ToastrService) {
    window.constructor = afs;
    this.HTTPheaders = {
      "Content-Type": "application/json",
    };

    this.options = {
      headers: this.HTTPheaders,
    };

    //this.retrieveUserList();
  }

  retrieveUserList(trainerId: number) {
    //if (this.userList == null) {
    let currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : "";
    this.http
      .get(
        environment.baseUrl +
          "admin/registry/?page=1&per_page=1000" +
          (currentUser.is_admin && !trainerId ? "&trainer_like=my_resources" : "") +
          (trainerId ? "&trainer_id=" + trainerId : ""),
        this.options
      )
      .subscribe((data: any) => {
        this.userList = data.data.registries.data;
        this.chats$.emit(this.userList);
      });
    //} else {
    //this.chats$.emit(this.userList);
    //}
  }

  initializeChats(uid: string, trainerId: number = null) {
    this.initializeSuperAdmin(uid);

    this.retrieveUserList(trainerId);

    this.messagesStorage = [];
  }

  initializeSuperAdmin(uid: string) {
    this.superAdminUid = uid;
  }

  /**
   *
   * @param receiver the UserID of the receiver
   * @param newMessageArrived if true: load messages starting from the first message not seen
   */
  initializeMessages(receiver: string, newMessageArrived: boolean) {
    this.messagesStorage = [];

    if (newMessageArrived) {
      this.getFirstMessageNotSeen(receiver).then((firstMessageNotSeen: DocumentData) => {
        this.getMessagesByReceiver(receiver, firstMessageNotSeen, true).then((messages) => {
          // set as seen each message
          messages.forEach((message: any) => {
            if (message.receiver == this.superAdminUid && !message.seenByReceiver) {
              this.setMessageAsSeen(receiver, message.messageId);
            }
          });

          // reset notification
          this.updateChatsNotification(receiver);
        });
      });
    } else {
      this.getMessagesByReceiver(receiver);
    }
  }

  setMessageAsSeenByCoach(receiver: string) {
    this.updateChatsNotification(receiver, false);
  }

  /**
   *
   * @param messages array of messages to be ordered
   * @param order type of order to apply to messages
   * @returns the array messages ordered by the order chosen
   */
  ordering(messages: any, order: string = "asc") {
    const column = "timeSent";
    if (order.toLowerCase() == "desc") {
      return messages.sort((a, b) => (a[column] > b[column] ? -1 : b[column] > a[column] ? 1 : 0));
    }
    return messages.sort((a, b) => (a[column] > b[column] ? 1 : b[column] > a[column] ? -1 : 0));
  }

  chats() {
    return this.chats$;
  }

  getMessageNotification(): EventEmitter<string> {
    return this.messagesFromReceiversEmitter;
  }

  /**
   *  This event emitter is used to get the messages,
   *  every time they change (added, ordered ...)
   * @returns the event emitter to subscribe to
   */
  getMessages(): EventEmitter<any> {
    return this.messages;
  }

  /**
   *
   * @param messages array of messages to add to the messages already stored
   */
  setMessages(messages: any): void {
    this.messagesStorage = this.messagesStorage.concat(messages);
    const ordered = [...this.ordering(this.messagesStorage, "asc")];
    this.messages.emit(ordered);
  }

  async getFirstMessageNotSeen(receiverUid: string) {
    const c = collection(this.afs, this.collectionName + "/" + receiverUid + "/messages");
    const q = query(c, where("seenByReceiver", "==", false), where("receiver", "==", this.superAdminUid), orderBy("timeSent", "asc"), limit(1));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((d) => d.data())[0];
  }

  async getFirstMessageSeen(receiverUid: string) {
    const c = collection(this.afs, this.collectionName + "/" + receiverUid + "/messages");
    const q1 = query(c, where("seenByReceiver", "==", true), where("receiver", "==", this.superAdminUid), orderBy("timeSent", "desc"), limit(1));

    const q2 = query(c, where("seenBySender", "==", true), where("sender", "==", this.superAdminUid), orderBy("timeSent", "desc"), limit(1));

    const querySnapshot1 = await getDocs(q1);
    const querySnapshot2 = await getDocs(q2);
    const message1 = querySnapshot1.docs.map((d) => d.data())[0];
    const message2 = querySnapshot2.docs.map((d) => d.data())[0];
    return message1.timeSent > message2.timeSent ? message1 : message2;
  }

  /**
   *
   * @param receiverUid
   * @param startAfterMessage
   * @param retrieveAll if true retrieve all messages, (default: false)
   * @returns get last X messages ordered by desc (newer first)
   */
  async getMessagesByReceiver(receiverUid: string, startAfterMessage: DocumentData | string = null, retrieveAll: boolean = false) {
    const c = collection(this.afs, this.collectionName + "/" + receiverUid + "/messages");

    let q;
    if (startAfterMessage != null) {
      const msgId = typeof startAfterMessage == "string" ? startAfterMessage : startAfterMessage.messageId;
      const docSnap = await getDoc(doc(c, msgId));
      q = retrieveAll
        ? query(c, orderBy("timeSent", "asc"), startAt(docSnap))
        : query(c, orderBy("timeSent", "desc"), limit(this.messagesLimit), startAfter(docSnap));
    } else {
      q = query(c, orderBy("timeSent", "desc"), limit(this.messagesLimit));
    }

    const querySnapshot = await getDocs(q);
    querySnapshot.docs.map((doc, k) => {
      const message = doc.data() as any;

      const messageId = message.messageId;
      this.message2doc[messageId] = doc;
    });

    const messages = querySnapshot.docs.map((doc) => doc.data());
    this.setMessages(messages);
    return messages;
  }

  /**
   * wait for a new message by receiver (chat already opened)
   */
  async waitForMessages(receiverUid: string) {
    // DEV: first message stands for "First access to the chat"?
    // is true also if no message exist
    let firstMessage = true;

    //unsubscribe if already subscribed
    if (this.unsubscribeWaitForMessages != null) {
      this.unsubscribeWaitForMessages();
    }

    const c = collection(this.afs, this.collectionName, receiverUid, "messages");

    const q = query(c, orderBy("timeSent", "desc"), limit(1));

    // onSnapshot
    // Creates a document snapshot immediately with the current contents of the single document.
    // Then, each time the contents change, another call updates the document snapshot.
    this.unsubscribeWaitForMessages = onSnapshot(q, (querySnapshot) => {
      const messages = [];

      // DEV: doc refers only to the last message?
      querySnapshot.forEach((doc) => {
        const message = doc.data();
        const messageId = message.messageId;
        if (!this.message2doc.hasOwnProperty(messageId)) {
          this.message2doc[messageId] = doc;
          messages.push(message);
          if (message.receiver === this.superAdminUid && !message.seenByReceiver) {
            // set message as seen
            this.setMessageAsSeen(message.sender, messageId);

            // update notification map
            this.updateChatsNotification(message.sender);
          }
        }
      });

      if (!firstMessage) {
        this.setMessages(messages);
      } else {
        firstMessage = false;
      }
    });
  }

  /**
   * wait for new messages
   */
  async waitForOtherMessages() {
    // const c = collection(this.afs, this.collectionName);
    const c = doc(this.afs, this.collectionName, this.superAdminUid);
    this.unsubscribeWaitForOtherMessages = onSnapshot(c, (docSnapshot) => {
      this.messagesFromReceivers = docSnapshot.data();
      this.messagesFromReceiversEmitter.emit(this.messagesFromReceivers);
    });
  }

  /**
   * send message to receiver
   * @param to id of the receiver
   * @param data text of the message
   */
  async sendMessageTo(to: string, data: string) {
    const messageId = uuid.v1();

    const timeSent = new Date().getTime();

    const message: DocumentData = {
      messageId: messageId,
      receiver: to,
      sender: this.superAdminUid,
      data: data,
      type: "text",
      seenByReceiver: false,
      seenBySender: true,
      timeSent: timeSent,
    };
    await this.sendDataTo(to, message);
  }

  // send audio to receiver
  async sendAudioTo(to: string, messageId: string, data: string) {
    const timeSent = new Date().getTime();

    const message: DocumentData = {
      messageId: messageId,
      receiver: to,
      sender: this.superAdminUid,
      data: data,
      type: "audio",
      seenByReceiver: false,
      seenBySender: true,
      timeSent: timeSent,
    };
    await this.sendDataTo(to, message);
  }

  async sendVideoTo(to: string, messageId: string, data: string) {
    const timeSent = new Date().getTime();

    const message: DocumentData = {
      messageId: messageId,
      receiver: to,
      sender: this.superAdminUid,
      data: data,
      type: "video",
      seenByReceiver: false,
      seenBySender: true,
      timeSent: timeSent,
    };
    await this.sendDataTo(to, message);
  }

  async sendImageTo(to: string, messageId: string, data: string) {
    const timeSent = new Date().getTime();

    const message: DocumentData = {
      messageId: messageId,
      receiver: to,
      sender: this.superAdminUid,
      data: data,
      type: "image",
      seenByReceiver: false,
      seenBySender: true,
      timeSent: timeSent,
    };
    await this.sendDataTo(to, message);
  }

  async sendDataTo(to: string, message: DocumentData) {
    const receiverMessage = doc(this.afs, this.collectionName, to, "messages", message.messageId);

    //associate message to receiver message => "send message"
    await setDoc(receiverMessage, message);

    const info: DocumentData = {
      receiver: to,
      newMessageForCoach: false,
      newMessageForTrainee: true,
    };
    await this.updateDocumentInfo(to, info);
    const user = this.userList.filter((u) => u.firebase_uid === to);

    if (user.length > 0) {
      const data = {
        id: user[0].user_id,
      };
      this.http.post(environment.baseUrl + "admin/send-push-notification", JSON.stringify(data), this.options).subscribe((data: any) => {});
    }
  }

  async updateDocumentInfo(to: string, info: DocumentData) {
    const receiverInfo = doc(this.afs, this.collectionName, to);
    // DEV: receiverInfo is the reference to the doc related to the receiver
    // Why specify receiver id if already is in the receiver doc path?
    await setDoc(receiverInfo, info, { merge: true });
  }

  async updateChatsNotification(from: string, notSeen: boolean = true) {
    const receiverInfo = doc(this.afs, this.collectionName, this.superAdminUid);
    const info = {};
    info[from] = notSeen;
    await setDoc(receiverInfo, info, { merge: true });
  }

  async updateChatsNotificationForTest(from: string) {
    const receiverInfo = doc(this.afs, this.collectionName, this.superAdminUid);
    const info = {};
    info[from] = true;
    await setDoc(receiverInfo, info, { merge: true });
  }

  async sendMessageForTest(from: string, data: string) {
    const messageId = uuid.v1();

    const receiverMessage = doc(this.afs, this.collectionName, from, "messages", messageId);

    const timeSent = new Date().getTime();

    const message: DocumentData = {
      messageId: messageId,
      receiver: this.superAdminUid,
      sender: from,
      data: data,
      type: "text",
      seenByReceiver: false,
      seenBySender: true,
      timeSent: timeSent,
    };
    await this.updateChatsNotificationForTest(from);
    await setDoc(receiverMessage, message);
  }

  // set message sent by receiver (trainee) as seen by coach
  async setMessageAsSeen(receiver: string, messageId: string) {
    const newMessageDoc = doc(this.afs, this.collectionName, receiver, "messages", messageId);
    const data = {};
    data["seenByReceiver"] = true;
    try {
      await updateDoc(newMessageDoc, data);
    } catch (e) {
      console.error("Message not seen", e);
    }
  }

  async saveData(fileBlob: any, dataType: string, receiver: string) {
    const ext = fileBlob.type.split("/")[1];
    const messageId = uuid.v1();
    const path = `chat/${dataType}/${receiver}/`;
    const filePath = `${path}/${messageId}.${ext}`;

    const storageRef = ref(this.storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, fileBlob);
    await uploadTask;

    const url = await getDownloadURL(storageRef);

    if (dataType == "audio") {
      await this.sendAudioTo(receiver, messageId, url);
    } else if (dataType == "image") {
      await this.sendImageTo(receiver, messageId, url);
    } else if (dataType == "video") {
      await this.sendVideoTo(receiver, messageId, url);
    }
  }

  startListenForPushNotification() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      const pushNotification: PushNotification = payload as PushNotification;
      this.toastr.info(pushNotification.notification.body, pushNotification.notification.title, {
        positionClass: "toast-bottom-left",
        tapToDismiss: true,
      });
      this.newPushNotification.emit(pushNotification);
    });
  }

  /**
   *  This event emitter is used to listen for
   *  new firebase push notification
   * @returns the event emitter to subscribe to
   */
  listenForPushNotifications(): EventEmitter<PushNotification> {
    return this.newPushNotification;
  }
}
