<div class="togglebutton switch-sidebar-mini">
  <bSwitch
    *ngFor="let e of value"
    [switch-on-color]="e.onColor"
    [switch-off-color]="e.offColor"
    [switch-off-text]="e.offText"
    [switch-on-text]="e.onText"
    [(ngModel)]="e.value"
    (click)="routeTo(e)"></bSwitch>
</div>
