import { MessageFormatterService } from "./../../services/messageFormatter.service";
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { UtentiService } from "../../pages/utenti/utenti.service";
import { LoadingService } from "../../loading.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

interface Notification {
  id: number;
  message: string;
  readed: number;
}

@Component({
  selector: "app-notifications-sidebar",
  templateUrl: "./notifications-sidebar.component.html",
  styleUrls: ["./notifications-sidebar.component.css"],
})
export class NotificationsSidebarComponent implements OnInit, OnDestroy {
  @HostListener("document:click", ["$event.target"])
  onClick(targetElement: HTMLElement) {
    //clicked inside the notifications sidebar component
    const clickedInside = this.elRef.nativeElement.contains(targetElement);
    //the bell icon used to open the notifications sidebar component
    const bellIcon = document.querySelector("#bellNotificationsSidebar");
    const clickedOnBell = bellIcon?.contains(targetElement);

    if (!clickedInside && !clickedOnBell && this.sidebarIsOpen) {
      this.closeNav();
    }
    if (clickedOnBell) {
      this.sidebarIsOpen = true;
      this.notifications = [];
      this.getNotifications(this.defaultPage, this.defaultPerPage);
    }
  }

  //current user actually logged
  currentUser: any;

  scrollDistance = 1;
  bounce: boolean = false;
  bounce_up: boolean = false;
  loader: boolean = false;
  //loader used to hide a specific notification
  notLoader: boolean = false;
  idNotLoader: number;
  sidebarIsOpen: boolean = false;
  defaultPage: number = 1;
  defaultPerPage: number = 15;
  currentPage: number = this.defaultPage;
  currentPerPage: number = this.defaultPerPage;
  totalNotifications: number = 0;
  lastPage: number;
  currentType: "New" | "Readed" | "Trainer" = "New";

  notifications: Notification[] = [];

  constructor(
    private elRef: ElementRef,
    private userService: UtentiService,
    private messageFormatter: MessageFormatterService,
    private loadingService: LoadingService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentUser = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : "";
  }

  ngOnDestroy(): void {
    this.loadingService.setControlOfLoader("Interceptor");
  }

  async getNotifications(page: number, perPage: number) {
    //already loading?
    if (this.loader === true) return;
    //finished notifications?
    if (this.lastPage && page > this.lastPage) return;

    this.loader = true;
    this.currentPage = page;
    this.currentPerPage = perPage;
    try {
      this.loadingService.setControlOfLoader("Manual");
      let res = await this.userService
        .getNotifications(this.currentType, page, perPage)
        .toPromise();
      let notificationsFromServer = res.data.notifications.data;
      this.loader = false;
      notificationsFromServer.forEach((notification) =>
        this.notifications.push(notification)
      );

      //totalNotification useful only for new notification
      if (this.currentType === "New")
        this.totalNotifications = res.data.notifications.total;

      this.lastPage = res.data.notifications.last_page;
    } catch (e) {
      console.log(e);
    } finally {
      this.loader = false;
      this.loadingService.setControlOfLoader("Interceptor");
    }
  }

  scrollTrigger(event) {
    let element = event.currentTarget;
    var sc = element.scrollHeight - element.clientHeight - element.scrollTop;

    if (sc === 0) {
      this.startBounceAnimation();
    } else if (element.scrollTop == 0) {
      this.startBounceAnimationUp();
    }
  }

  startBounceAnimation() {
    this.bounce = true;
    setTimeout(() => (this.bounce = false), 1100);
  }

  startBounceAnimationUp() {
    this.bounce_up = true;
    setTimeout(() => (this.bounce_up = false), 1100);
  }

  onScrollDown() {
    this.getNotifications(this.currentPage + 1, this.currentPerPage);
  }

  closeNav() {
    if (this.sidebarIsOpen) {
      document
        .getElementById("notificationsSidepanel")
        .classList.remove("active");
      const body = document.querySelector("body");
      body.classList.remove("notificationsSidepanel-activated");
      this.sidebarIsOpen = false;
    }
  }

  openNotification(type, notification) {
    if (type === "New") {
      this.notificationReaded(notification.id);
    }
    if (notification.action === "progress") {
      this.router.navigate([notification.progress_url]);
    } else if (notification.action === "main_check") {
      this.router.navigate([notification.main_check_url]);
      sessionStorage.setItem("mainCheckId", notification.main_check_id);
    }
    this.closeNav();
  }

  activeList(type: "New" | "Readed" | "Trainer") {
    //no further action if no change
    if (this.currentType === type) return;
    this.lastPage = null;

    this.currentType = type;
    this.getNotifications(this.defaultPage, this.defaultPerPage);
    this.notifications = [];
  }

  async notificationReaded(notificationId: number) {
    try {
      this.loadingService.setControlOfLoader("Manual");
      this.notLoader = true;
      this.idNotLoader = notificationId;
      let res = await this.userService
        .notificationReaded(notificationId)
        .toPromise();
      if (res.success) {
        this.totalNotifications--;
        const index = this.notifications.findIndex(
          (notification) => notification.id === notificationId
        );
        if (index !== -1) {
          this.notifications.splice(index, 1); // Remove the notification with the specified id from the notifications array
        }
        //restore notification array length
        if (this.notifications.length < this.defaultPerPage) {
          //upload new notification
          this.onScrollDown();
        }
      } else {
        this.toastr.error(
          res.err.error.message,
          this.messageFormatter.getErrorTitle(),
          {
            positionClass: "toast-bottom-left",
            tapToDismiss: true,
          }
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.idNotLoader = null;
      this.notLoader = false;
      this.loadingService.setControlOfLoader("Interceptor");
    }
  }
}
