import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LOCALE_ID, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "./components/components.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";

import { AppRoutes } from "./app.routing";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { Interceptor } from "./interceptors/interceptor";
import { BannerIntegrazioneComponent } from "./pages/banner-integrazione/banner-integrazione.component";
import { ReactiveFormsModule } from "@angular/forms";
import { environment } from "../environments/environment";
import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import { getFirestore, provideFirestore } from "@angular/fire/firestore";
import { getStorage, provideStorage } from "@angular/fire/storage";
import { InvioNotificheMassiveComponent } from "./pages/invio-notifiche-massive/invio-notifiche-massive.component";
import { Ng2SmartTableModule } from "ng2-smart-table";
import { ChatPreloadingStrategy } from "./services/ChatPreloadingStrategy";
import { registerLocaleData } from "@angular/common";
import localeIt from "@angular/common/locales/it";
import localeRoExtra from "@angular/common/locales/extra/ro";

registerLocaleData(localeIt, "it", localeRoExtra);

@NgModule({
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent, BannerIntegrazioneComponent, InvioNotificheMassiveComponent],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
    }),
    NgbModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-left",
      tapToDismiss: true,
      timeOut: 5000,
      progressBar: true,
      preventDuplicates: true,
    }), // ToastrModule added
    RouterModule.forRoot(AppRoutes, {
      preloadingStrategy: ChatPreloadingStrategy, // <-- use custom preloading strategy
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    ComponentsModule,
    ReactiveFormsModule,
    Ng2SmartTableModule,
  ],
  providers: [
    ChatPreloadingStrategy,
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
    },
    { provide: LOCALE_ID, useValue: "it-IT" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
