<a
  *ngFor="let e of value"
  [attr.id]="e.btnId"
  [class]="e.classList"
  [title]="e.title"
  (click)="routeTo(e)"
  href="javascript:void(0)"
>
  <i
    *ngIf="!e.stackIcon && e.iconClass"
    [class]="e.iconClass"
    aria-hidden="true"
  ></i>
  <img
    *ngIf="e.iconType === 'svg' && e.svgIconPath"
    [src]="e.svgIconPath"
    width="16"
    height="16"
    alt="{{ e.title }}"
  />
  <span *ngIf="e.stackIcon && e.iconClassList" [class]="e.stackClass">
    <i *ngFor="let ic of e.iconClassList" [class]="ic"></i>
  </span>
  <span class="text_action_btn">
    {{ e.text }}
  </span>
</a>
